




import ProjectValuationMarket from "@/components/project-valuation-market/ProjectValuationMarket.vue";
import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({
  components: { ProjectValuationMarket },
})
export default class MarketAnalysisView extends Vue {}
